export default [
	{
		title: "Dashboard",
		route: "dashboard",
		roles: ["ADMINISTRATOR"],
		icon: "GridIcon",
	},
	{
		title: "Administradores",
		route: "administradores",
		roles: ["ADMINISTRATOR"],
		icon: "UserIcon",
	},
	{
		title: "Todas as publicações",
		route: "todas-publicacoes",
		roles: ["USER"],
		icon: "GridIcon",
	},
	{
		title: "Busca",
		route: "busca",
		roles: ["USER"],
		icon: "SearchIcon",
	},
	{
		title: "Datas Especiais",
		route: "datas-especiais",
		roles: ["USER"],
		icon: "CalendarIcon",
	},
	{
		title: "Peças de Marketing",
		route: "pecas-de-marketing",
		roles: ["USER"],
		icon: "Volume1Icon",
	},
	{
		title: "Templates",
		route: "templates",
		roles: ["USER"],
		icon: "LayoutIcon",
	},
	{
		title: "Temas",
		route: "temas",
		roles: ["USER"],
		icon: "ImageIcon",
	},
	{
		title: "Cursos",
		route: "cursos",
		roles: ["USER"],
		icon: "BookOpenIcon",
	},
	{
		roles: ["ADMINISTRATOR"],
		title: "Publicações",
		icon: "FileTextIcon",
		children: [
			{
				title: "Publicações",
				route: "publicacoes",
			},
			{
				title: "Categorias",
				route: "categorias",
			},
			{
				title: "Subcategorias",
				route: "subcategorias",
			},
			// {
			// 	title: "Gerenciamento de midias",
			// 	route: "gerenciamento-midias",
			// },
			{
				title: "Calendário",
				route: "calendario",
			},
		],
	},
];
