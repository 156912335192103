<template>
	<p class="clearfix mb-0">
		<!-- <span class="float-md-left d-block d-md-inline-block mt-25">
			<b-link class="ml-25" href="https://espressolabs.com.br" target="_blank">Espresso Labs</b-link>
			<span class="d-none d-sm-inline-block"> &nbsp;© {{ new Date().getFullYear() }}</span>
		</span> -->

		<!-- <span class="float-md-right d-none d-md-block">
			Desenvolvido pela <b-link href="https://espressolabs.com.br" target="_blank">Espresso Labs</b-link>
		</span> -->
	</p>
</template>

<script>
	// import { BLink } from "bootstrap-vue";

	export default {
		// components: {
		// 	BLink,
		// },
	};
</script>
